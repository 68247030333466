import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"

const LeichteSpracheKrank = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheKrankIndexQuery {
      burse: file(relativePath: { eq: "leichte-sprache/burse.jpg" }) {
        ...mediumImage
      }
      garten: file(relativePath: { eq: "leichte-sprache/garten.jpg" }) {
        ...mediumImage
      }
      zimmer: file(
        relativePath: { eq: "leichte-sprache/zimmer-hyperion.jpg" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlin ist krank"
        description="Hölderlin hat eine psychische Krankheit. Das bedeutet: Seine Seele ist krank. Man weiß nicht genau, welche Krankheit Hölderlin hat. Hölderlin geht es nicht gut. Er ist oft unglücklich. Manchmal ist er auch verwirrt."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Krank",
                link: "/leichte-sprache/ueber-hoelderlin/krank",
              },
            ]}
          />
          <PageTitle>Hölderlin ist krank</PageTitle>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/08-krank" />
          </Constrain>
          <Paragraph dropcap={true}>
            Hölderlin hat eine psychische Krankheit. <br />
            Das bedeutet: <br />
            Seine Seele ist krank. <br />
            Man weiß nicht genau, <br />
            welche Krankheit Hölderlin hat. <br />
            Hölderlin geht es nicht gut. <br />
            Er ist oft unglücklich. <br />
            Manchmal ist er auch verwirrt.
          </Paragraph>
        </Stack>

        <Stack>
          <Paragraph>
            Im Jahr 1806 kommt Hölderlin nach Tübingen ins Kranken∙haus. <br />
            Er ist mehr als ein halbes Jahr im Kranken∙haus. <br />
            Dort bekommt er Medikamente. <br />
            Die Medikamente helfen ihm aber nicht. <br />
          </Paragraph>
          <Constrain>
            <Image
              image={data.burse.childImageSharp.gatsbyImageData}
              caption="Dieses Gebäude war früher ein Kranken∙haus. Hier wurde Hölderlin behandelt."
              attribution="Gudrun de Maddalena"
              alt="Ein großes rosafarbenes Gebäude. Davor: Der Neckar und die Stadtmauer."
            />
          </Constrain>
        </Stack>

        <Stack>
          <Paragraph>
            Manchmal darf Hölderlin in den Garten vom Kranken∙haus. <br />
            Heute gehört der Garten vom Kranken∙haus zum Museum. <br />
            Wenn Hölderlin im Garten ist, <br />
            geht es ihm besser. <br />
            Hölderlin ist gerne draußen in der Natur. <br />
            Das macht ihn aber nicht gesund. <br />
            Sein Arzt sagt: <br />
            Hölderlin wird nicht mehr gesund. <br />
            Wir können ihm nicht helfen. <br />
            Deshalb kann er nicht im Kranken∙haus bleiben.
          </Paragraph>
          <Constrain>
            <Image
              image={data.garten.childImageSharp.gatsbyImageData}
              attribution="Gudrun de Maddalena"
              alt="Eine große Steintreppe mit einem Holztor führt in einen Garten. Am Garten fließt der Neckar vorbei."
            />
          </Constrain>
        </Stack>

        <Stack>
          <Paragraph>
            Hölderlin kann aber nicht alleine wohnen. <br />
            Dafür ist er zu krank. <br />
            Deshalb darf er bei Ernst Zimmer in Tübingen wohnen. <br />
            Ernst Zimmer wohnt neben dem Kranken∙haus. <br />
            Er hat Hölderlin schon einmal im Kranken∙haus besucht. <br />
            Ihm gefallen Hölderlins Bücher und Gedichte. <br />
          </Paragraph>
          <Paragraph>
            Er hat Hölderlin gern. <br />
            Deshalb sagt Ernst Zimmer: <br />
            Hölderlin kann in meinem Haus wohnen. <br />
            Ernst Zimmer und seine Familie kümmern sich um Hölderlin. <br />
            Hölderlin wohnt viele Jahre bei Familie Zimmer. <br />
          </Paragraph>
          <Constrain>
            <Image
              image={data.zimmer.childImageSharp.gatsbyImageData}
              caption="Dieses Bild vom Hölderlin∙turm hat Ernst Zimmer gemalt. Er hat auch Hölderlins Buch ›Hyperion‹ gelesen. Es hat ihm sehr gefallen."
              attribution="David Franck"
              alt="Eine Vitrine mit einem grünen, alten Buch. Dahinter hängt ein Aquarell-Bild vom Neckar am Hölderlinturm."
            />
          </Constrain>
          <Paragraph>
            Als Ernst Zimmer stirbt, <br />
            kümmert sich seine Tochter Lotte Zimmer um Hölderlin. <br />
            Hölderlin wohnt in einem Zimmer im Turm von Ernst Zimmers Haus.{" "}
            <br />
            Deshalb heißt dieser Turm heute Hölderlin∙turm.
          </Paragraph>
        </Stack>

        <LeichteSpracheNext variant="turm" />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheKrank
